.navbars{
    display: flex;
    padding-left: 8%;
    justify-content: start;
    align-items: center;
    color: black;
    border: 5px black;
    background-color:#864313;
    border-bottom: 1px solid gray;
    cursor: pointer;

    font-family: "Honk", system-ui;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 2rem;
    font-variation-settings:
      "MORF" 15,
      "SHLN" 50;
    
}

.backbtn{
    border-radius: 10%;
    padding-left: 10px;
    padding-right: 10px;
    /* border: 1px solid rgb(112, 110, 110); */
    color: white;
}



#navheading{
   
}



@media screen and (min-width:600px) {
    #navheading{
        margin-left: 35%;
    }
}

@media screen and (max-width:600px) {
    #navheading{
        margin-left: 15%;
    }
}