/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(11, 14, 48);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.madimi-one-regular {
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.nabla-coin {
  font-family: "Nabla", system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  font-variation-settings:
    "EDPT" 100,
    "EHLT" 12;
}
.honk-coin {
  font-family: "Honk", system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 5rem;
  font-variation-settings:
    "MORF" 15,
    "SHLN" 50;
}