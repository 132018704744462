.homeheading{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style:italic;
}

.entrtytext{
    display: flex;
    align-items: center;
}

.entryhead{
    color: antiquewhite;
}

.entrysecondhead{
    color: wheat;
}
.entrypara{
    color:wheat
}



.carousalimg{
    height: 470px;
    max-width:400px ;
    /* box-shadow: 5px 5px 4px #0b050529; */
    border-radius: 20px;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;

}
body{
    background-color: burlywood;
}

.carousalimgmyc{
    /* height: 470px; */
    max-width:250px ;
    /* box-shadow: 5px 5px 4px #0b050529; */
    border-radius: 10px;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    cursor: pointer;
    border: 1.5px dashed palegoldenrod;

}

.carousalimgantique{
    height: 340px;
    max-width: 260px;
    border-radius: 10px;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    cursor: pointer;
    border: 1px dashed palegoldenrod;

}

.coinbox{
    padding: 20px;
    cursor: pointer;
    
}


.container{
    display: flex;
    height: 100%vh;
    align-items: center;

}

.heading{
    /* margin-left: 10px; */
    text-align: center;
    color: burlywood;
}

.btndiv{
    /* margin-top: 20px; */
    text-align: center;

}

.control-dots , .control-arrow {
    display: none;
}


@media screen and (max-width:575px) {
    .row{
        text-align: center;
    }   
    
}





#root{
  background-color: rgb(13, 16, 29);

}
.coinname{
    color: darkgoldenrod;
}

.coindesc{
    color: gold;
}


@media (min-width:992px) {
    .lastbtn{
        visibility: hidden;
        display: none;
    }
    .nabla-coin {
        font-size: 3rem
    }
    .btndiv{
        margin-top: 0px;
    }
}
@media (max-width:992px) {
    .firstbtn{
        visibility: hidden;
    }
    .honk-coin {
        font-size: 3rem;
    }
   

}